import "./Footer.scss";
import { routes } from "../Navbar/utils/NavbarRoutes";
import { useNavigate } from "react-router-dom";
import { socials } from "../Socials/utils/socials";
import CompanyLogo from "./../../../assets/images/company-logo.png";
type Props = {};

function Footer({}: Props) {
  const navigate = useNavigate();
  function handleNavigate(route: string) {
    navigate(route);
  }
  return (
    <div className="footer">
      <div className="upper-footer">
        <div className="left-upper">
          <ul className="footer-navbar">
            {routes.map((route) => (
              <li
                className="footer-navbar-item"
                key={route.id}
                onClick={() => {
                  handleNavigate(route.route);
                }}
              >
                {route.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="middle-upper">
          <img src={CompanyLogo} alt="logo" className="footer-logo" />
        </div>
        <div className="right-upper">
          <ul className="footer-socials">
            {socials.map((icon, index) => (
              <li
                key={index}
                className="footer-social-item"
                data-color={icon.color}
              >
                <a href="#" data-url="#">
                  <i className={`fa-brands ${icon.class}`}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="middle-footer">
        <div className="middle-left">
          <span className="footer-email">Email: info@kihremodelingllc.com</span>
          <span className="footer-tel">Tel: +1 774 368 5774</span>
        </div>
        <div className="middle-right">
          Address: PO Box 2401 Brewster, MA 02631
        </div>
      </div>
      <div className="lower-footer">
        Copyright &copy; KIH Remodeling LLC. All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
