import { useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { routes } from "./utils/NavbarRoutes";
import PointerButton from "../Button/PointerButton";
import { useEffect, useState } from "react";
type Props = {};

function Navbar({}: Props) {
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState(window.location.pathname);
  console.log(window.location.pathname);
  console.log(selectedRoute);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  function handleNavigate(route: string) {
    navigate(route);
  }
  function handleNavbar() {
    setIsNavbarOpen((prev) => !prev);
  }
  useEffect(() => {
    if (window.innerWidth > 960) {
      setIsNavbarOpen(false);
    }
    function windowSize() {
      return window.innerWidth;
    }
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, [window.scrollY]);
  return (
    <nav className="navbar">
      <div className="navbar-info">
        <span className="info-tel">
          <span className="material-symbols-outlined telephone-icon">call</span>
          <span className="tel-number">+1 774 368 5774</span>
        </span>
      </div>
      <ul
        className={
          isNavbarOpen ? "navbar-list-active navbar-list" : "navbar-list"
        }
      >
        {routes.map((route) => (
          <li
            className={
              selectedRoute === `/${route.route}`
                ? "navbar-list-item item-active"
                : "navbar-list-item"
            }
            key={route.id}
            onClick={() => {
              handleNavigate(route.route);
              setSelectedRoute(`/${route.route}`);
            }}
          >
            {route.title}
          </li>
        ))}
      </ul>{" "}
      <div className="navbar-btn">
        <PointerButton title="Book Consult" link="/contact-us" />
      </div>
      <div className="navbar-menu" onClick={() => handleNavbar()}>
        <span
          className={
            isNavbarOpen
              ? "material-symbols-outlined menu-icon menu-icon-rotate"
              : "material-symbols-outlined menu-icon"
          }
        >
          menu
        </span>
      </div>
    </nav>
  );
}

export default Navbar;
