import Navbar from "../Navbar/Navbar";
import "./Banner.scss";
import CompanyLogo from "./../../../assets/images/company-logo.png";
import { useEffect, useState } from "react";
import PointerButton from "../Button/PointerButton";
import Socials from "../Socials/Socials";

type Props = {};

function Banner({}: Props) {
  const [carouselCount, setCarouselCount] = useState(0);
  const [hasCompletedCycle, setHasCompletedCycle] = useState(false);
  const totalImages = 3;

  useEffect(() => {
    if (!hasCompletedCycle) {
      const temp = setTimeout(() => {
        if (carouselCount < totalImages - 1) {
          setCarouselCount((prev) => prev + 1);
        } else {
          setCarouselCount(0);
          setHasCompletedCycle(true);
        }
      }, 5000);

      return () => {
        clearTimeout(temp);
      };
    }
  }, [carouselCount, hasCompletedCycle]);
  return (
    <div className="banner">
      <div className="banner-wrapper">
        <div className="navbar-wrapper">
          <Navbar />
        </div>
        <div className="company-logo">
          <img src={CompanyLogo} alt="Company logo" className="company-img" />{" "}
        </div>
        <div className="image-wrapper">
          <img
            src={require(`./../../../assets/images/banner-slide-0${
              carouselCount + 1
            }.jpg`)}
            alt="carousel"
            className="carousel-image"
          />
        </div>
        <div className="carousel-wrapper">
          <div className="carousel-bullets">
            {[0, 1, 2].map((list) => (
              <span
                key={list}
                className={
                  carouselCount === list
                    ? "carousel-bullet carousel-bullet-active"
                    : "carousel-bullet"
                }
                onClick={() => {
                  setCarouselCount(list);
                }}
              >
                <p
                  className={
                    carouselCount === list ? "bullet bullet-active" : "bullet"
                  }
                />
              </span>
            ))}
          </div>
        </div>
        <div className="text-wrapper">
          <h5 className="text-header">Desing Business</h5>
          <p className="text-info">Build Spaces To </p>

          <p className="text-info">Make Wonderful Living</p>
        </div>
        <div className="button-wrapper">
          <PointerButton title="Take Counsel" link="/contact-us" />
        </div>
        <div className="socials-wrapper">
          <Socials />
        </div>
      </div>
    </div>
  );
}

export default Banner;
