import { lazy, Suspense } from "react";
import "./App.css";
import Banner from "./components/shared/Banner/Banner";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/shared/Loader/Loader";
import Footer from "./components/shared/Footer/Footer";

const Homepage = lazy(() => import("./pages/HomePage/Homepage"));
const ServicesPage = lazy(() => import("./pages/Services/ServicesPage"));
const ContactUsPage = lazy(() => import("./pages/ContactPage/ContactUsPage"));
function App() {
  return (
    <div className="app">
      <Banner />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Homepage />
            </Suspense>
          }
        />
        <Route
          path="/services"
          element={
            <Suspense fallback={<Loader />}>
              <ServicesPage />
            </Suspense>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Suspense fallback={<Loader />}>
              <ContactUsPage />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
