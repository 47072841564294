function Loader() {
  return (
    <section className="loader">
      <div className="wrapper">
        <div className="loader">
          <div className="face">
            <div className="circle"></div>
          </div>
          <div className="face">
            <div className="circle"></div>
          </div>
        </div>
        {/* <div className="children">{children}</div> */}
      </div>
    </section>
  );
}

export default Loader;
