import React from "react";
import "./Socials.scss";
import { socials } from "./utils/socials";
type Props = {};

function Socials({}: Props) {
  return (
    <div className="container">
      <ul>
        {socials.map((icon, index) => (
          <li
            key={index}
            style={{ "--clr": icon.color } as React.CSSProperties}
            data-color={icon.color}
          >
            <a href="#" data-url="#">
              <i className={`fa-brands ${icon.class}`}></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Socials;
