import "./PointerButton.scss";
import { useNavigate } from "react-router-dom";
type Props = {
  title: string;
  link?: string;
};

function PointerButton({ title, link }: Props) {
  const navigate = useNavigate();
  function handleRedirectLink(link: string | undefined) {
    if (link) navigate(link);
  }
  return (
    <div className="btn-wrapper" onClick={() => handleRedirectLink(link)}>
      <button className="btn">{title}</button>
      <div className="btn-pointer-service">
        <span className="material-symbols-outlined arrow-forward">
          arrow_forward
        </span>
      </div>
    </div>
  );
}

export default PointerButton;
